
import Vue from 'vue'
import L, { latLng } from 'leaflet'
import { LMap, LTileLayer, LMarker, LTooltip, LControlZoom, LIcon } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import { environment } from '../environment'
import MarkerCircle from '@/components/common/MarkerCircle.vue'
import moment from 'moment'
import '@/plugins/draw-circle'
import '@/plugins/edit-circle'

export default Vue.extend({
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LControlZoom,
    LIcon,
    MarkerCircle,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
  props: {
    cddList: {
      type: Array as () => CDDSource[],
      default: () => [],
    },
    drawZones: {
      type: Boolean,
      default: false,
    },
    zones: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    zoom: environment.zoom,
    center: latLng(...environment.center),
    url: environment.osm,
    currentZoom: environment.zoom,
    currentCenter: latLng(...environment.center),
    mapOptions: {
      zoomSnap: 0.5,
      zoomControl: false,
      drawControl: true,
    },
    map: null as any,
    zoneId: 1,
    circle: null as any,
  }),
  computed: {
    visibleSources(): CDDSource[] {
      return this.cddList.filter((k: CDDSource) => k.location)
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs.myMap) return
      this.map = (this.$refs.myMap as any).mapObject

      if (this.$props.drawZones) {
        this.map.on('layeradd', () => {
          if (this.$props.zones.length < 1) {
            this.map.on('click', this.handleMapClick)
          }
        })
        this.$watch('zones', (newZones, prevZones) => {
          if (this.$props.zones.length) return

          prevZones.forEach((z: any) => {
            this.map.removeLayer(z.circle)
          })
        })
      }
    })
  },
  methods: {
    getImage(src: string) {
      return `${environment.photoOrigin}/${src}`
    },
    zoomUpdated(zoom: any) {
      this.zoom = zoom
    },
    getTimeTooltip(timeStamp: number) {
      return moment.utc(timeStamp).local().format('YYYY-MM-DD HH:mm:ss')
    },
    centerUpdated(center: any) {
      this.center = center
    },
    getCoordinates(kdd: CDDSource) {
      return latLng(kdd.location?.latitude, kdd.location?.longitude)
    },
    handleMapClick(evt: any) {
      const circle = L?.circle([evt.latlng.lat, evt.latlng.lng], 800)
      this.map?.addLayer(circle)
      circle?.editing.enable()
      this.$props.zones.push({ circle })
      this.map.off('click', this.handleMapClick)
    },
  },
})
