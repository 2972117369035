
import Vue from 'vue'
import { mapMutationsTyped, mapStateTyped } from './store'
import { environment } from './environment'
import Search from './views/search-by-signs.vue'
import { FinderAPI } from './api'

export default Vue.extend({
  components: {
    search: Search,
  },
  provide(): any {
    return {
      spaParent: this.$parent,
    }
  },
  data() {
    return {
      isDark: false,
      themeUnsubscribe: () => {},
    }
  },
  computed: {
    ...mapStateTyped(['lastRequestResult', 'isDarkTheme']),
    isSnackbarOpen: {
      get(): any {
        return !!this.lastRequestResult
      },
      set(value): any {
        if (!value) {
          this.$store.commit('setValue', ['lastRequestResult', null])
        }
      },
    },
    snackbarColor(): any {
      return this.lastRequestResult ? this.lastRequestResult.color : 'info'
    },
    snackbarText(): any {
      return this.lastRequestResult ? this.lastRequestResult.text : ''
    },
  },
  beforeMount() {
    this.$store.commit('setValue', ['api', new FinderAPI()])
  },
  created() {
    const themeEl = document.getElementById('theme') as HTMLLinkElement & {
      setTheme: () => void
    }
    this.setValue(['isDarkTheme', themeEl.getAttribute('theme-name') === 'dark'])
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === 'dark')
      themeEl.addEventListener('update', listener)
      return () => themeEl.removeEventListener('update', listener)
    }

    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.setValue(['isDarkTheme', newValue])
    })
    this.$vuetify.lang.current = environment.lang
  },
  destroyed() {
    this.themeUnsubscribe()
  },
  methods: {
    ...mapMutationsTyped(['setValue']),
  },
})
