import { AxiosInstance } from 'axios'

export class TrafficAPI {
  _http: AxiosInstance
  constructor(_http: AxiosInstance) {
    this._http = _http
  }

  public getBySigns(data: ISignsTrafficRequest): Promise<IZoneTraffic> {
    return this._http
      .get<IZoneTraffic>('/passages/contains/zone/filter/models', {
        params: data,
      })
      .then((response) => response.data)
  }

  public async getBrands() {
    return (await this._http.get<IBrandsResponse>('/vehicle/models')).data
  }
}
