
import Vue from 'vue'
import { mapActionsTyped, mapStateTyped } from '../store'
import { changeArraySignsList } from '@/utils'
import Map from '@/components/map.vue'
import SearchBar from '@/components/searchbar.vue'
import SearchResults from '@/components/search-results.vue'
import moment from 'moment-timezone'
import { environment } from '@/environment'

export default Vue.extend({
  name: 'SearchBySigns',
  components: {
    Map,
    SearchBar,
    SearchResults,
  },
  data() {
    return {
      zones: [] as any,
      params: {},
      loaderLength: 0,
      showProgressBar: true,
      offset: 0,
    }
  },
  computed: {
    ...mapStateTyped(['sourceList', 'isSourceListLoading', 'isSignsListLoading', 'signsList']),
    transformArrayTrafficList(): any {
      return changeArraySignsList(this.signsList)
    },
  },
  async mounted() {
    if (!this.sourceList.length && !this.isSourceListLoading) {
      this.$store.dispatch('loadSourceList')
    }
  },
  beforeDestroy() {
    this.$store.commit('reset')
    this.showProgressBar = true
  },
  methods: {
    ...mapActionsTyped(['loadSourceList', 'loadSignsList']),
    loadMore() {
      this.offset = this.offset + 1000
      this.showProgressBar = false
      this.$store.dispatch('loadSignsList', {
        ...this.params,
        limit: 1000,
        offset: this.offset,
      })
    },
    search({ from, to, number, brand }: any) {
      this.showProgressBar = true

      const [zone] = this.zones

      if (!zone) {
        this.$store.commit('setValue', [
          'lastRequestResult',
          {
            text: 'Область не выбрана!',
            color: 'info',
          },
        ])
        return
      }
      if ((to.getTime() - from.getTime()) / 1000 > 86400) {
        this.$store.commit('setValue', [
          'lastRequestResult',
          {
            text: 'Максимальный интервал поиска не должен превышать 24 часа!',
            color: 'info',
          },
        ])
        return
      }
      const radius = zone.circle.getRadius()
      if (radius >= 2000) {
        this.$store.commit('setValue', [
          'lastRequestResult',
          {
            text: 'Выбранный радиус больше 2 км!',
            color: 'info',
          },
        ])
        return
      }

      const center = zone.circle.getLatLng()
      const fromFormated = moment(from).format('YYYY-MM-DDTHH:mm:00')
      const toFormated = moment(to).format('YYYY-MM-DDTHH:mm:00')

      const params: any = {
        search_start_datetime: moment(fromFormated).tz(environment.tz).format(),
        search_end_datetime: moment(toFormated).tz(environment.tz).format(),
        regexp: number || null,
        models: brand && brand.length > 0 ? brand.toString() : null,
        radius: radius,
        center_latitude: center.lat,
        center_longitude: center.lng,
      }

      this.$store.commit('setValue', ['signsList', []])

      this.params = { ...params }

      this.$store.dispatch('loadSignsList', {
        ...params,
        limit: 1000,
        offset: 0,
      })
    },
    clear() {
      this.zones = []
      this.$store.commit('setValue', ['signsList', []])
      this.showProgressBar = true
    },
  },
})
