/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { mapState, mapMutations, mapActions } from 'vuex'
import { FinderAPI } from './api'
import { mapActionItems } from './utils'

// eslint-disable-next-line camelcase

const initialState = () => ({
  api: {} as FinderAPI,
  isDarkTheme: false as boolean,
  isSourceListLoading: false as boolean,
  sourceList: [] as CDDSource[],
  signsList: [] as IZoneTraffic[],
  isSignsListLoading: false as boolean,
  isBrandsLoading: false as boolean,
  brands: [] as IIDName[],
  lastRequestResult: undefined as
    | {
        text: string
        color: 'success' | 'info' | 'error' | 'warning'
      }
    | undefined,
})

type State = ReturnType<typeof initialState>

const mutations = {
  setValue<T extends keyof State>(state: State, [key, value]: [T, State[T]]) {
    state[key] = value
  },
  reset(state: State) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      state[key] = s[key]
    })
  },
}

type Mutations = typeof mutations

type ActionArguments = {
  commit: <K extends keyof Mutations>(
    method: keyof Mutations,
    payload: Parameters<Mutations[K]>[1],
  ) => void
  state: State
  dispatch: <T extends keyof Actions>(
    action: T,
    // @ts-ignore
    payload?: Parameters<Actions[T]>[1],
  ) => PromiseLike<unknown> | void
}

const actions = {
  async loadSourceList({ state, commit }: ActionArguments) {
    try {
      commit('setValue', ['isSourceListLoading', true])
      const response = await state.api?.cdd?.getSourceList?.()
      if (response && Array.isArray(response)) {
        commit('setValue', ['sourceList', response])
      } else {
        console.log('error')
      }
    } catch (error) {
      console.error('An error occurred:', error)
    } finally {
      commit('setValue', ['isSourceListLoading', false])
    }
  },
  async loadSignsList({ state, commit }: ActionArguments, params: ISignsTrafficRequest) {
    commit('setValue', ['isSignsListLoading', true])
    try {
      const response = await state.api?.traffic.getBySigns(params)
      if (response && !response.length) {
        commit('setValue', [
          'lastRequestResult',
          {
            text: 'Ничего не найдено',
            color: 'info',
          },
        ])
        return
      }

      if (response && response.length >= 10000) {
        commit('setValue', [
          'lastRequestResult',
          {
            text: 'Найдено более 10 000 записей. уточните критерии поиска!',
            color: 'info',
          },
        ])
      }

      const result = mapActionItems(response)
      commit('setValue', ['signsList', result])
    } catch (error) {
      commit('setValue', [
        'lastRequestResult',
        {
          text: 'Ошибка при отправке запроса!',
          color: 'warning',
        },
      ])
    } finally {
      commit('setValue', ['isSignsListLoading', false])
    }
  },
  async loadBrands({ state, commit }: ActionArguments) {
    try {
      commit('setValue', ['isBrandsLoading', true])
      const response = await state?.api?.traffic?.getBrands?.()
      if (response) {
        const modifiedResponse = (response as IIDName[]).map((brand: { name: string }) => {
          if (brand.name === 'Unknown') {
            return { ...brand, name: 'Не определена' }
          } else {
            return brand
          }
        })
        commit('setValue', ['brands', modifiedResponse as IIDName[]])
      }
    } catch (error) {
      console.log('brands not loaded', error)
    } finally {
      commit('setValue', ['isBrandsLoading', false])
    }
  },
}

type Actions = typeof actions

export const mapStateTyped = <T extends keyof State, G extends { [Key in T]: () => State[Key] }>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

export const mapMutationsTyped = <
  T extends keyof Mutations,
  G extends {
    [K in T]: (I?: Parameters<Mutations[K]>[1]) => ReturnType<Mutations[K]>
  },
>(
  keys: T[],
): G => {
  return mapMutations(keys) as G
}

export const mapActionsTyped = <
  T extends keyof Actions,
  G extends {
    // @ts-ignore
    [Key in T]: (I?: Parameters<Actions[Key]>[1]) => ReturnType<Actions[Key]>
  },
>(
  keys: T[],
): G => {
  return mapActions(keys) as G
}

export default {
  state: initialState(),
  mutations,
  actions,
}
